import { Container } from '@chakra-ui/layout'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../../../containers/Layout'

const Ans = () => {
  return (
    <Layout>
      <Container maxW="container.sm">
        <StaticImage src="answer.png" alt="酒駕" />
      </Container>
    </Layout>
  )
}

export default Ans
